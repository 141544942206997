import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocale } from '../../context/LocaleContext';
import { Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Box, CssBaseline, ListItemButton, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DensitySmallOutlinedIcon from '@mui/icons-material/DensitySmallOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { AddShoppingCartRounded, BugReportRounded, StoreRounded, AddCircleOutlineRounded, CategoryRounded, AttachMoneyRounded, ShoppingBagRounded } from '@mui/icons-material';
import NavbarAccordian from './NavbarAccordian';

const BurgerMenu = () => {
	const { locale, changeLocale, messages } = useLocale();
	const { user, signOut } = useAuth();
	const { themeObj } = useTheme();
	const location = useLocation();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const Navigate = useNavigate();

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	const handleClick = (path) => {
		Navigate(path);
		setDrawerOpen(false);
	};

	const menuItems = [
		{
			name: "All in One",
			href: '/dashboard',
			icon: <DashboardRoundedIcon />,
			type: 'link'
		},
		{
			name: "Items",
			icon: <CategoryRounded />,
			type: 'category',
			children: [
				{
					name: "Inventory",
					href: '/inventory',
					icon: <Inventory2OutlinedIcon />
				},
				{
					name: "Add Items",
					href: '/add-items',
					icon: <AddCircleOutlineRounded />
				}
			]
		},
		{
			name: "Purchase",
			icon: <ShoppingBagRounded />,
			type: 'category',
			children: [
				{
					name: "Purchase Transaction",
					href: '/purchases',
					icon: <ShoppingCartRoundedIcon />
				},
				{
					name: "Add Purchase",
					href: '/add-purchase',
					icon: <AddShoppingCartRounded />
				},
				{
					name: "Vendors",
					href: '/vendors',
					icon: <StoreRounded />
				}
			]
		},
		{
			name: "Sales",
			icon: <AttachMoneyRounded />,
			type: 'category',
			children: [
				{
					name: "Sale Transaction",
					href: '/sales',
					icon: <CurrencyExchangeRoundedIcon />
				},
				{
					name: "Add Sale",
					href: '/add-sales',
					icon: <SellRoundedIcon />
				}
			]
		},
		{
			name: 'Instant Sell',
			href: '/insta-sell',
			icon: <FlashOnRoundedIcon />,
			type: 'link'
		},
		// {
		// 	name: messages.navbar['barcode'],
		// 	href: '/barcode',
		// 	icon: <DensitySmallOutlinedIcon sx={{ transform: "rotate(90deg)" }} />
		// },
		// {
		// 	name: messages.navbar['report'],
		// 	href: '/report',
		// 	icon: <AssessmentRoundedIcon/>
		// },
		// {
		// 	name: messages.navbar['forecast'],
		// 	href: '/forecast',
		// 	icon: <TrendingUpRoundedIcon/>
		// },
		{
			name: "Support",
			href: '/support',
			icon: <BugReportRoundedIcon />,
			type: 'link'
		},
		{
			name: messages.navbar['profile'],
			href: '/profile',
			icon: <AccountCircleRoundedIcon fontSize='medium' />,
			type: 'link'
		}
	]

	return (
		<>
			<CssBaseline />
			<Toolbar sx={{ minHeight: "unset" }}>
				<IconButton
					edge="start"
					aria-label="menu"
					onClick={handleDrawerToggle}
					size="large"
				>
					<MenuIcon htmlColor={themeObj.primary} fontSize="medium" />
				</IconButton>
			</Toolbar>
			<Drawer SlideProps={{ timeout: 400, easing: { enter: 'ease-in-out', exit: 'ease-in-out' } }} anchor="left" open={drawerOpen} onClose={handleDrawerToggle} PaperProps={{ sx: { backgroundColor: themeObj.card, borderTopRightRadius: 20, borderBottomRightRadius: 20, padding: 1.5 } }}>
				<Box
					sx={{ width: 300, backgroundColor: themeObj.card, boxSizing: "border-box" }}
					role="presentation"
					// onClick={handleDrawerToggle}
					// onKeyDown={handleDrawerToggle}
				>
					<List>
						{menuItems.map((element, index) => (
							element.type === 'link' ?
								<ListItem key={index}>
									<ListItemButton sx={{ '& .Mui-focusVisible': { backgroundColor: location.pathname === element.href ? themeObj.primary : "transparent" }, borderRadius: 3, backgroundColor: location.pathname === element.href ? themeObj.primary : "transparent" }} onClick={() => handleClick(element.href)}>
										<Stack flexDirection='row' alignItems='center' gap={2}>
											{React.cloneElement(element.icon, { fontSize: 'medium', htmlColor: location.pathname === element.href ? themeObj.contrast : themeObj.primary })}
											<ListItemText primary={element.name} sx={{ color: location.pathname === element.href ? themeObj.background : themeObj.title, '& span': { fontSize: "1.1rem" } }} />
										</Stack>
									</ListItemButton>
								</ListItem>
								:
								<NavbarAccordian
									key={index}
									isOpen={element.children.some((child) => child.href === location.pathname)}
									component={
										<Stack flexDirection='row' alignItems='center' gap={2}>
											{React.cloneElement(element.icon, { fontSize: 'medium', htmlColor: themeObj.primary })}
											<ListItemText primary={element.name} sx={{ color: themeObj.title, '& span': { fontSize: "1.1rem" } }} />
										</Stack>
									}
								>
									{element.children.map((child, index) =>
										<ListItem key={index}>
											<ListItemButton sx={{ '& .Mui-focusVisible': { backgroundColor: location.pathname === child.href ? themeObj.primary : "transparent" }, borderRadius: 3, backgroundColor: location.pathname === child.href ? themeObj.primary : "transparent" }} onClick={() => handleClick(child.href)}>
												<Stack flexDirection='row' alignItems='center' gap={2}>
													{React.cloneElement(child.icon, { fontSize: 'medium', htmlColor: location.pathname === child.href ? themeObj.contrast : themeObj.primary })}
													<ListItemText primary={child.name} sx={{ color: location.pathname === child.href ? themeObj.background : themeObj.title, '& span': { fontSize: "1.1rem" } }} />
												</Stack>
											</ListItemButton>
										</ListItem>
									)}
								</NavbarAccordian>
						))}
						{/* <ListItem>
							<ListItemButton sx={{ '& .Mui-focusVisible': { backgroundColor: location.pathname === element.href ? themeObj.primary : "transparent" }, borderRadius: 3, backgroundColor: location.pathname === element.href ? themeObj.primary : "transparent" }} onClick={() => Navigate(element.href)}>
								<Stack flexDirection='row' alignItems='center' gap={2}>
									{React.cloneElement(element.icon, { fontSize: 'medium', htmlColor: location.pathname === element.href ? themeObj.contrast : themeObj.primary })}
									<ListItemText primary={element.name} sx={{ color: location.pathname === element.href ? themeObj.background : themeObj.title, '& span': { fontSize: "1.1rem" } }} />
								</Stack>
							</ListItemButton>
						</ListItem> */}
						{user ?
							<ListItem>
								<ListItemButton onClick={() => signOut().then(() => window.location.href = 'https://vanguardai.in/kraya')}>
									<Stack flexDirection='row' alignItems='center' gap={2}>
										<LogoutIcon fontSize='medium' htmlColor={themeObj.primary} />
										<ListItemText primary={messages.navbar['logout']} sx={{ color: themeObj.title, '& span': { fontSize: "1.1rem" } }} />
									</Stack>
								</ListItemButton>
							</ListItem>
							:
							<ListItem>
								<ListItemButton onClick={() => Navigate('/signin')}>
									<Stack flexDirection='row' alignItems='center' gap={2}>
										<LoginRoundedIcon fontSize='medium' htmlColor={themeObj.primary} />
										<ListItemText primary={messages.navbar['login']} sx={{ color: themeObj.title, '& span': { fontSize: "1.1rem" } }} />
									</Stack>
								</ListItemButton>
							</ListItem>
						}
						{/* <ListItem>
							<ListItemButton onClick={() => changeLocale()}>
								<Stack flexDirection='row' alignItems='center' gap={2}>
									<LogoutIcon fontSize='medium' htmlColor={themeObj.primary}/>
									<ListItemText sx={{ textTransform: 'uppercase', color: themeObj.title, fontWeight: 600 }} primary='Change Locale' />
								</Stack>
							</ListItemButton>
						</ListItem> */}
					</List>
				</Box>
			</Drawer>
		</>
	);
};

export default BurgerMenu;
