import { Stack, Grid, TextField, Paper, Typography, MenuItem, InputAdornment, Box, Collapse, IconButton } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "../../context/ThemeContext";
import { ExpandMoreRounded } from "@mui/icons-material";
import DateField from "../Sales/DateField";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc);

const validationSchema = Yup.object({
	transaction_date: Yup.string().required('Transaction Date is required'),
	charges_amount: Yup.number().typeError('Charges must be a number'),
});

const TransactionDetails = forwardRef(({ onFormSubmit, amount }, ref) => {
	const { themeObj } = useTheme();
	const [tags, setTags] = useState([]);
	const [expanded, setExpanded] = useState(false);

	const formik = useFormik({
		initialValues: {
			transaction_date: dayjs(Date.now()),
			charges_amount: '0',
			tags: [],
			notes: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit("transaction", {
				...values,
				transaction_date: dayjs.utc(values.transaction_date).format(),
			});
		},
	});
	React.useEffect(() => {
		setTags([
			{ value: 'returned', name: 'Returned' },
			{ value: 'partially_returned', name: 'Partially Returned' },
			{ value: 'discounted', name: 'Discounted' },
			{ value: 'modified', name: 'Modified' },
			{ value: 'refunded', name: 'Refunded' },
			{ value: 'payment_pending', name: 'Payment Pending' },
			{ value: 'partial_payment', name: 'Partial Payment' },
			{ value: 'credit_given', name: 'Credit Given' },
			{ value: 'cash_on_delivery', name: 'Cash on Delivery' },
			{ value: 'vip_customer', name: 'VIP Customer' },
			{ value: 'corporate', name: 'Corporate' },
			{ value: 'regular', name: 'Regular' },
			{ value: 'new_customer', name: 'New Customer' },
			{ value: 'wholesale', name: 'Wholesale' }
		]);
	}, []);

	useImperativeHandle(ref, () => ({
		hide: () => {
			setExpanded(false)
		},
		show: () => {
			setExpanded(true);
		},
		requestSubmit: async () => {
			await formik.submitForm();
		},
		reset: () => {
			formik.resetForm();
		}
	}));

	return (
		<Box>
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 10px', borderRadius: 1, backgroundColor: themeObj.secondary, height: '60px', display: "flex", alignItems: "center" }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={10.5}>
						<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
							<Typography textAlign="left" color={themeObj.title} fontSize="1.1rem">Transaction Details</Typography>
							<Stack alignItems="flex-end">
								<Typography color={themeObj.title} fontSize="0.7rem">Total Price</Typography>
								<Typography color={themeObj.title} fontSize="0.9rem">₹ {Math.round(((amount.total_amount || 0) + (amount.charges_amount || 0)) * 100) / 100  ?? '0'}</Typography>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={1.5}>
						<IconButton >
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<form
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper sx={{ borderRadius: 1, padding: "10px", backgroundColor: themeObj.card }}>
						<Stack gap={1.5}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										label="Subtotal"
										value={Math.round((amount.sub_total || 0) * 100) / 100 ?? '0'}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											readOnly: true,
											startAdornment: <InputAdornment position="start"><Typography color={themeObj.primary} fontWeight={600}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										label="Total Discount"
										value={Math.round((amount.discount_amount || 0) * 100) / 100 ?? '0'}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											readOnly: true,
											startAdornment: <InputAdornment position="start"><Typography color={themeObj.primary} fontWeight={600}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										id="charges_amount"
										name="charges_amount"
										label="Charges Amount"
										value={Math.round((amount.charges_amount || 0) * 100) / 100 ?? '0'}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											readOnly: true,
											startAdornment: <InputAdornment position="start"><Typography color={themeObj.primary} fontWeight={600}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										label="Total Amount"
										value={Math.round(((amount.total_amount || 0) + (amount.charges_amount || 0)) * 100) / 100  ?? '0'}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											readOnly: true,
											startAdornment: <InputAdornment position="start"><Typography color={themeObj.primary} fontWeight={600}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
							</Grid>
							<TextField
								select
								size="small"
								fullWidth
								id="tags"
								name="tags"
								label="Tags"
								value={formik.values.tags}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.tags && Boolean(formik.errors.tags)}
								helperText={formik.touched.tags && formik.errors.tags}
								sx={{
									fontSize: "0.8rem",
									color: themeObj.title,
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									},
									'& .MuiSelect-icon': {
										color: themeObj.title
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
								SelectProps={{
									multiple: true,
									MenuProps: {
										sx: {
											'& .MuiMenu-paper': {
												backgroundColor: themeObj.card,
											},
											'& .MuiMenu-list': {
												p: 0
											}
										}
									}
								}}
							>
								{tags.map((t, idx) =>
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} key={idx} value={t.value}>
										{t.name}
									</MenuItem>
								)}
							</TextField>
							<DateField
								size="small"
								fullWidth
								id="transaction_date"
								name="transaction_date"
								label="Sales Date*"
								value={formik.values.transaction_date}
								onChange={formik.handleChange}
							/>
							<TextField
								size="small"
								fullWidth
								multiline
								rows={2}
								id="notes"
								name="notes"
								label="Notes"
								value={formik.values.notes ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.notes && Boolean(formik.errors.notes)}
								helperText={formik.touched.notes && formik.errors.notes}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Stack>
					</Paper>
				</form>
			</Collapse>
		</Box>
	)
});

export default TransactionDetails;