import HomePage from '../pages/HomePage';
import SaleTransactionsPage from '../pages/SaleTransaction/SaleTransactionsPage';
import InventoryPage from '../pages/Inventory/InventoryPage';
import BarcodePage from '../pages/BarcodePage';
import ReportPage from '../pages/ReportPage';
import ForecastPage from '../pages/ForecastPage';
import InstaSellPage from '../pages/InstaSellPage';
import SignInPage from '../pages/SignInPage';
import ProfilePage from '../pages/ProfilePage';
import ItemPage from '../pages/ItemPage';
import UnderConstruction from '../components/UnderConstruction';
import OnboardingPage from '../pages/Onboarding/OnboardingPage';
import StoreCreatePage from '../pages/StoreCreate/StoreCreatePage';
import SalesPage from '../pages/SalesPage';
import PurchasePage from '../pages/PurchasePage';
import AddItemPage from '../pages/AddItemPage';
import PurchaseTransactionsPage from '../pages/PurchaseTransaction/PurchaseTransactionsPage';
import VendorPage from '../pages/Vendors/VendorPage';
import TicketsPage from '../pages/Tickets/TicketsPage';
import NotificationsPage from '../pages/NotificationsPage';

const protectedRoutes = [
	{ path: '/dashboard', component: <HomePage /> },
	{ path: '/sales', component: <SaleTransactionsPage /> },
	{ path: '/purchases', component: <PurchaseTransactionsPage /> },
	{ path: '/inventory', component: <InventoryPage /> },
	{ path: '/inventory/:itemId', component: <InventoryPage /> },
	{ path: '/insta-sell', component: <InstaSellPage /> },
	{ path: '/barcode', component: <BarcodePage /> },
	{ path: "/vendors", component: <VendorPage /> },
	{ path: "/vendors/:vendorId", component: <VendorPage /> },
	// { path: '/report', component: <ReportPage /> },
	// { path: '/forecast', component: <ForecastPage /> },
	{ path: '/profile', component: <ProfilePage /> },
	{ path: '/logout', component: <UnderConstruction /> },
	{ path: '/onboarding', component: <OnboardingPage /> },
	{ path: '/create-store', component: <StoreCreatePage /> },
	{ path: '/sales', component: <SalesPage /> },
	{ path: '/purchase', component: <PurchasePage />},
	{ path: '/add-items', component: <AddItemPage /> },
	{ path: '/add-sales', component: <SalesPage /> },
	{ path: '/add-purchase', component: <PurchasePage /> },
	{ path: '/support', component: <TicketsPage /> },
	{ path: '/notifications', component: <NotificationsPage /> },
];
const publicRoutes = [
	{ path: '/signin', component: <SignInPage /> },
]

export { protectedRoutes, publicRoutes };