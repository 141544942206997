import { ExpandMoreRounded } from "@mui/icons-material";
import { Box, Button, Collapse, IconButton, ListItem, ListItemButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";

export default function NavbarAccordian({ children, component, isOpen }) {
	const [open, setOpen] = useState(isOpen);
	const { themeObj } = useTheme();

	return (
		<Box width="100%">
			<ListItem>
				<ListItemButton sx={{ width: "100%", '& .Mui-focusVisible': { backgroundColor: "transparent" }, borderRadius: 3, backgroundColor: "transparent" }} onClick={() => setOpen(!open)}>
					<Stack flexDirection="row" justifyContent="space-between" alignItems="center" gap={1} width="100%">
						{component}
						<IconButton>
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: open ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
						</IconButton>
					</Stack>
				</ListItemButton>
			</ListItem>
			{/* <Button onClick={() => setOpen(!open)} variant="outlined" sx={{ '& .Mui-focusVisible': { backgroundColor: "transparent" }, borderRadius: 3, backgroundColor: "transparent" }}>
				{component}
			</Button> */}
			<Collapse in={open}>
				<Stack ml={2} gap={0.2}>
					{children}
				</Stack>
			</Collapse>
		</Box>
	);
}