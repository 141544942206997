import { Box, Button, Chip, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTheme } from "../../context/ThemeContext";
import { CategoryRounded, CloudOffRounded, CurrencyRupeeRounded, ErrorOutlineRounded, LaunchRounded, PercentRounded, ScatterPlotRounded, ShoppingCartOutlined, TollRounded, WarningAmberRounded } from "@mui/icons-material";
import ItemItem from "./ItemItem";
import { useNavigate } from "react-router-dom";
import TextWithInfo from "../Common/TextWithInfo";

const COLORS = {
	YELLOW: "#eab308",
	GREEN: "#22c55e",
	RED: "#fd1821",
	ORANGE: "#f97316",
	BLUE: "#3b82f6",
	PURPLE: "#a855f7",
	TEAL: "#008080",
	LIGHT_BLUE: "#ADD8E6",
	SKY_BLUE: "#87CEEB",
	CORNFLOWER_BLUE: "#6495ED",
	INDIGO: "#4B0082",
};

export default function HomeStats({ data, loaded }) {
	const { themeObj } = useTheme();
	const navigate = useNavigate();
	const [timeFrame, setTimeFrame] = useState('daily');
	const { inventory_summary, sales_overview, profit_estimate, top_selling_items } = data;

	const timeframes = [
		{ label: "Today", value: "daily" },
		{ label: "Weekly", value: "weekly" },
		{ label: "Monthly", value: "monthly" },
		{ label: "Quarter", value: "quarterly" },
		{ label: "Yearly", value: "yearly" },
	];

	console.log(profit_estimate)

	if (!loaded) return null;

	return (
		<Grid container spacing={1.25}>
			<Grid item xs={12}>
				<Stack gap={1}>
					<Grid container sx={{ position: "relative" }}>
						<Grid item xs={6}>
							<Paper elevation={0} sx={{ border: "1.5px solid", padding: "20px 35px 35px 0px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
								<Stack justifyContent="center" alignItems="center">
									<CategoryRounded htmlColor={COLORS.BLUE} />
									<TextWithInfo
										text="Total Items"
										info={inventory_summary?.total_items?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{inventory_summary?.total_items?.value || 0}</Typography>
								</Stack>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} sx={{ border: "1.5px solid", padding: "20px 0px 35px 35px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									<ScatterPlotRounded htmlColor={COLORS.GREEN} />
									<TextWithInfo
										text="Total Quantity"
										info={inventory_summary?.total_quantity?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{inventory_summary?.total_quantity?.value || 0}</Typography>
								</Stack>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} sx={{ border: "1.5px solid", padding: "35px 35px 20px 0px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									<ErrorOutlineRounded htmlColor={COLORS.YELLOW} />
									<TextWithInfo
										text="Low Stock"
										info={inventory_summary?.low_stock_items?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{inventory_summary?.low_stock_items?.value || 0}</Typography>
								</Stack>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} sx={{ border: "1.5px solid", padding: "35px 0px 20px 35px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									<WarningAmberRounded htmlColor={COLORS.RED} />
									<TextWithInfo
										text="Out of Stock"
										info={inventory_summary?.out_of_stock_items?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>{inventory_summary?.out_of_stock_items?.value || 0}</Typography>
								</Stack>
							</Paper>
						</Grid>
						<Grid sx={{ position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} item xs={6}>
							<Paper elevation={0} sx={{ border: "1.5px solid", padding: "20px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
								<Stack justifyContent="center" alignItems="center" height="100%">
									<CurrencyRupeeRounded htmlColor={COLORS.ORANGE} />
									<TextWithInfo
										text="Total Value"
										info={inventory_summary?.total_value?.info}
										textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
										infoProps={{ htmlColor: themeObj.subtitle }}
									/>
									<Typography color={themeObj.title} fontSize="0.8rem" fontWeight={600}>₹ {inventory_summary?.total_value?.value || 0}</Typography>
								</Stack>
							</Paper>
						</Grid>
					</Grid>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", flexDirection: "row", gap: 0.2, justifyContent: "space-between", px: 1, py: 1 }}>
					{timeframes.map((t, index) =>
						<Chip
							key={index}
							variant={timeFrame === t.value ? "filled" : "outlined"}
							label={t.label}
							color="warning"
							size="small"
							onClick={() => setTimeFrame(t.value)}
						/>
					)}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Grid container rowSpacing={2.5} columnSpacing={0.5}>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="center">
								<CurrencyRupeeRounded htmlColor={COLORS.GREEN} />
								<TextWithInfo
									text="Total Sales"
									info={sales_overview[timeFrame]?.total_sales?.info}
									textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
									infoProps={{ htmlColor: themeObj.subtitle }}
								/>
								<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>₹ {sales_overview[timeFrame]?.total_sales?.value}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="center" height="100%">
								<ShoppingCartOutlined htmlColor={COLORS.BLUE} />
								<TextWithInfo
									text="Total Transactions"
									info={sales_overview[timeFrame]?.total_transactions?.info}
									textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
									infoProps={{ htmlColor: themeObj.subtitle }}
								/><Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{sales_overview[timeFrame]?.total_transactions?.value}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="center" height="100%">
								<CategoryRounded htmlColor={COLORS.PURPLE} />
								<TextWithInfo
									text="Total Items Sold"
									info={sales_overview[timeFrame]?.total_items_sold?.info}
									textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
									infoProps={{ htmlColor: themeObj.subtitle }}
								/><Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>₹ {sales_overview[timeFrame]?.total_items_sold?.value}</Typography>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack justifyContent="center" alignItems="center" height="100%">
								<PercentRounded htmlColor={COLORS.YELLOW} />
								<TextWithInfo
									text="Average Sale"
									info={sales_overview[timeFrame]?.total_sales?.info}
									textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
									infoProps={{ htmlColor: themeObj.subtitle }}
								/><Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>{sales_overview[timeFrame]?.average_sale?.value}</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs>
				<Paper sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary }}>
					<Stack justifyContent="center" alignItems="center" height="100%">
						<TextWithInfo
							text="Profit Estimate"
							info={profit_estimate[timeFrame]?.info}
							textProps={{ color: themeObj.subtitle, fontSize: "0.8rem" }}
							infoProps={{ htmlColor: themeObj.subtitle }}
						/>
						<Typography color={themeObj.title} fontSize="1rem" fontWeight={600}>₹ {profit_estimate[timeFrame]?.value}</Typography>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs="auto">
				<Button onClick={() => navigate('/insta-sell')} fullWidth sx={{ border: "1.5px solid", padding: "10px 15px", backgroundColor: themeObj.card, borderColor: themeObj.secondary, height: "100%" }} startIcon={<LaunchRounded fontSize="large" htmlColor={COLORS.BLUE} />} >
					<Typography color={themeObj.title} fontSize="0.8rem">Instant Sale</Typography>
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Divider variant="middle" sx={{ "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="1.2rem">Top Selling Items</Typography>
				</Divider>
			</Grid>
			<Grid item xs={12}>
				{top_selling_items[timeFrame]?.map((item, index) =>
					<ItemItem key={index} {...item} />
				)}
				{top_selling_items[timeFrame]?.length === 0 &&
					<Paper sx={{ border: "3px dotted", padding: "10px 15px", backgroundColor: "transparent", borderColor: themeObj.secondary }}>
						<Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
							<CloudOffRounded htmlColor={themeObj.subtitle} />
							<Typography textAlign="center" color={themeObj.title} fontSize="1rem">No Sales Data</Typography>
						</Stack>
					</Paper>
				}
			</Grid>
		</Grid >
	);
}