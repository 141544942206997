import React, { useState, useEffect } from "react";
import { Box, Button, Chip, Collapse, Divider, Grid, Slide, Stack, Typography } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import NotificationItem from "../components/Notification/NotificationItem";
import TransactionItemLoader from "../components/Transaction/TransactionItemLoader";
import Pagination from "../components/Common/Pagination";
import { useTheme } from "../context/ThemeContext";
import { markNotificationRead, markNotificationUnread, viewNotifications } from "../apis/notificationApi";

const PAGESIZE = 30;

const sampleData = [
	{
		_id: "NOT-192C4E1D277BB6BECE8AB",
		store_id: "test_store_123",
		notification_category: "stock",
		notification_status: "unread",
		notification_title: "Stock Alert",
		notification_description: "Low stock level detected in inventory",
		created_at: "2024-10-25T18:18:46.044+00:00",
		updated_at: "2024-10-25T18:18:46.044+00:00"
	},
	{
		_id: "NOT-192C4E26BBA3D5EF64D49",
		store_id: "STO-671BCED913BB00EC71",
		notification_category: "stock",
		notification_status: "read",
		notification_title: "Stock Update",
		notification_description: "Stock levels have been updated successfully",
		created_at: "2024-10-24T15:30:22.123+00:00",
		updated_at: "2024-10-24T15:30:22.123+00:00"
	},
	{
		_id: "NOT-192C4E3A8D91F4B2E7C35",
		store_id: "STO-892DEFC147AA33BD89",
		notification_category: "transaction",
		notification_status: "unread",
		notification_title: "New Transaction",
		notification_description: "A new transaction has been recorded in the system",
		created_at: "2024-10-23T12:45:33.876+00:00",
		updated_at: "2024-10-23T12:45:33.876+00:00"
	},
	{
		_id: "NOT-192C4E4B9CA2E5D3F8D46",
		store_id: "STO-561ABED824CC11EC92",
		notification_category: "inventory",
		notification_status: "read",
		notification_title: "Inventory Alert",
		notification_description: "Critical low stock levels detected for multiple items",
		created_at: "2024-10-22T09:15:10.567+00:00",
		updated_at: "2024-10-22T09:15:10.567+00:00"
	},
	{
		_id: "NOT-192C4E5C7DB3F6E4G9E57",
		store_id: "STO-234CFED935DD22FD03",
		notification_category: "stock",
		notification_status: "unread",
		notification_title: "Stock Check Required Immediately Plkease Check",
		notification_description: "Regular stock verification needs to be performed",
		created_at: "2024-10-21T20:05:55.789+00:00",
		updated_at: "2024-10-21T20:05:55.789+00:00"
	},
	{
		_id: "NOT-192C4E6D8EC4G7F5H0F68",
		store_id: "STO-783DGED046EE33GE14",
		notification_category: "transaction",
		notification_status: "read",
		notification_title: "Transaction Complete",
		notification_description: "Transaction process has been completed successfully",
		created_at: "2024-10-20T14:40:18.234+00:00",
		updated_at: "2024-10-20T14:40:18.234+00:00"
	},
	{
		_id: "NOT-192C4E7E9FD5H8G6I1G79",
		store_id: "STO-445EHED157FF44HF25",
		notification_category: "inventory",
		notification_status: "unread",
		notification_title: "Inventory Check",
		notification_description: "Monthly inventory verification is due",
		created_at: "2024-10-19T11:25:42.456+00:00",
		updated_at: "2024-10-19T11:25:42.456+00:00"
	},
	{
		_id: "NOT-192C4E8F0GE6I9H7J2H80",
		store_id: "STO-916FIED268GG55IG36",
		notification_category: "stock",
		notification_status: "read",
		notification_title: "Critical Stock Alert",
		notification_description: "Multiple items have reached critical stock levels",
		created_at: "2024-10-18T08:10:33.678+00:00",
		updated_at: "2024-10-18T08:10:33.678+00:00"
	},
	{
		_id: "NOT-192C4E9G1HF7J0I8K3I91",
		store_id: "STO-327GJED379HH66JH47",
		notification_category: "transaction",
		notification_status: "unread",
		notification_title: "New Order",
		notification_description: "New customer order has been received",
		created_at: "2024-10-17T16:55:27.890+00:00",
		updated_at: "2024-10-17T16:55:27.890+00:00"
	}
];

export default function NotificationsPage() {
	const [renderedData, setRenderedData] = useState([]);
	const [loaded, setLoaded] = useState(true);
	const [unread, setUnread] = useState(false);

	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const [noData, setNoData] = useState(false);

	const { themeObj } = useTheme();


	// const readNotifications = async () => {
	// 	const response = await markNotificationRead(selectedNotifications);
	// 	if (response.success) {
	// 		const newRenderedData = renderedData.map(e => {
	// 			if (selectedNotifications.includes(e._id)) {
	// 				e.notification_status = "read";
	// 			}
	// 			return e;
	// 		});
	// 		setRenderedData(newRenderedData);
	// 		setSelectedNotifications([]);
	// 	}
	// }

	const onClick = async (notification_id) => {
		const response = await markNotificationRead([notification_id]);
		if (response.success) {
			setRenderedData(renderedData.map(e => e._id === notification_id ? { ...e, notification_status: "read" } : e));
		}
	}

	const reload = (unread) => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			if (unread) {
				options.notificationStatus = "unread";
			}
			const response = await viewNotifications(options)
			if (response.success) {
				const data = response.data;
				console.log(data);
				setRenderedData(data.notifications);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		getData();
	}

	const pageSearch = (page) => {
		const getData = async () => {
			const options = {
				page: page,
				pageSize: PAGESIZE,
			};
			const response = await viewNotifications(options)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(data.notifications);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		setLoaded(false);
		// getData();
		window.scrollTo(0, 0);
	}

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			const response = await viewNotifications(options)
			if (response.success) {
				const data = response.data;
				console.log(data);
				setRenderedData(data.notifications);
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		getData();
	}, [])

	useEffect(() => {
		if (renderedData.length === 0) return setNoData(true);
	}, [renderedData])

	useEffect(() => {
		if (loaded) reload(unread);
	}, [unread])

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
			{noData ?
				(loaded && renderedData.length === 0) && (
					<Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center", width: "100%", my: 5 }}>
						<Typography variant="h5" sx={{ color: themeObj.title }}>No Data <span style={{ color: themeObj.primary, fontWeight: 600 }}>!</span></Typography>
					</Box>
				)
				:
				<>
					<Stack gap={1}>
						<Box>
							{unread ?
								<Chip label="Unread" color="primary" sx={{ margin: 0.5 }} onClick={() => { setUnread(false) }} />
								:
								<Chip label="Unread" variant="outlined" color="primary" sx={{ margin: 0.5 }} onClick={() => { setUnread(true) }} />
							}
						</Box>
						<TransitionGroup>
							{loaded ?
								(renderedData?.length !== 0 &&
									renderedData.map((e, i) => (
										<Collapse key={e._id}>
											<NotificationItem {...e} onClick={() => onClick(e._id)} />
											{i !== renderedData.length - 1 && <Divider sx={{ borderColor: themeObj.subtitle }} />}
										</Collapse>
									))
								)
								:
								Array.from({ length: 20 }, (e, i) =>
									<Collapse key={i} sx={{ marginBottom: '12px' }}>
										<TransactionItemLoader />
									</Collapse>
								)
							}
						</TransitionGroup>
					</Stack>
					<Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
						<Pagination
							page={page}
							setPage={setPage}
							hasNext={hasNext}
						/>
					</Box>
					{/* <Slide direction="up" in={selectedNotifications.length > 0} mountOnEnter unmountOnExit>
						<Grid columnSpacing={2} px={3} container sx={{ position: "fixed", bottom: "10px", left: 0, backdropFilter: "blur(7px)" }}>
							<Grid item xs={6}>
								<Button
									fullWidth
									variant="contained"
									color="warning"
									onClick={readNotifications}
								>
									Mark as Read
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									fullWidth
									variant="contained"
									color="info"
									onClick={unreadNotifications}
								>
									Mark as Unread
								</Button>
							</Grid>
						</Grid>
					</Slide> */}
				</>
			}
		</div >
	);
}