import apiInstance from './apiInstance';

const viewNotifications = async (options = {}) => {
	const params = {
		page: options.page || 1,
		page_size: options.pageSize || 10
	};

	if (options.notificationStatus) params.notification_status = options.notificationStatus;
	if (options.notificationCategory) params.notification_category = options.notificationCategory;
	if (options.notificationId) params.notification_id = options.notificationId;

	try {
		const response = await apiInstance.get('/notifications/view_notifications', { params });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to view notifications."
		};
	}
};

const markNotificationRead = async (notificationIds) => {
	const params = { read: true };

	try {
		const response = await apiInstance.post('/notifications/mark_notifications',
			notificationIds,
			{ params }
		);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to mark notification."
		};
	}
};

const markNotificationUnread = async (notificationIds) => {
	const params = { read: false };

	try {
		const response = await apiInstance.post('/notifications/mark_notifications',
			notificationIds,
			{ params }
		);
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			message: error.response?.data?.message || "Network error, unable to mark notification."
		};
	}
};

export { viewNotifications, markNotificationRead, markNotificationUnread };