import React, { useState, useEffect } from "react";
import { useRedirection } from "../context/RedirectionContext";
import { Box, LinearProgress, Stack, Typography, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import Navbar from './Navbar/Navbar'
import NotificationBar from "./NotificationBar";
import VoiceSearch from "./FAB/VoiceSearch";
import AlertComponent from "./Alert";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useTheme } from "../context/ThemeContext";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAuth } from "../context/AuthContext";

export default function Layout({ children }) {
	const [redirectionData, setRedirectionData] = useState();
	const [redirectingTo, setRedirectingTo] = useState();
	const [processing, setProcessing] = useState(false);
	const match = useMediaQuery('(max-width: 600px')
	const { themeObj } = useTheme();
	const { refresh } = useAuth();

	const { setRedirection } = useRedirection();

	const location = useLocation();

	useEffect(() => {
		console.log(location);
	}, [location])

	useEffect(() => {
		if (redirectionData) {
			switch (redirectionData.function_name) {
				case "view_inventory":
					setRedirection('/inventory', { filters: redirectionData.filters, itemCategory: redirectionData.item_category });
					setRedirectingTo('Inventory');
					break;
				case "show_transaction":
					setRedirection('/transactions', { duration: redirectionData.duration, itemCategory: redirectionData.item_category, filters: redirectionData.transaction_type });
					setRedirectingTo('Transactions');
					break
				case "generate_report":
					setRedirection('/report', {});
					setRedirectingTo('Report');
					break;
				case "add_transaction":
					setRedirection('/barcode', { itemList: redirectionData.item_list, transactionType: redirectionData.transaction_type });
					setRedirectingTo('Barcode');
					break;
				default:
					break;
			}
			setTimeout(() => {
				setRedirectingTo();
			}, 1500);
		}
	}, [redirectionData])

	return (location.pathname === '/' || location.pathname === '/signin' ?
		<>
			{children}
		</>
		:
		<Box sx={{ position: "relative", display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: themeObj.background, overflowX: "hidden", color: themeObj.white }}>
			{processing && <LinearProgress sx={{ position: "absolute", width: "100%", backgroundColor: themeObj.primary_light, '& .MuiLinearProgress-bar': { backgroundColor: themeObj.primary } }} />}
			{match ?
				<>
					<header style={{ margin: 'min(5vw, 30px)', position: "fixed", width: "min(90vw, 1300px)", zIndex: 10 }}>
						<Navbar />
					</header>
					<Box minHeight="calc((min(5vw, 30px) * 2) + 50px)"></Box>
					<NotificationBar />
					<Box component='main' sx={{ flexGrow: 1, position: "relative", display: "flex" }}>
						<Box sx={{ mr: 'auto', ml: 'auto', maxWidth: 'min(90vw, 1300px)', flexGrow: 1 }}>
							{children}
						</Box>
					</Box>
					{/* <Box sx={{ zIndex: 10000, backdropFilter: 'brightness(0.4)', position: "fixed", marginLeft: "auto", bottom: "20px", right: "20px", borderRadius: "50%" }}>
						<VoiceSearch processing={processing} setProcessing={setProcessing} setData={setRedirectionData} />
					</Box> */}
				</>
				:
				<Box sx={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}>
					<Stack sx={{ flexDirection: "row", gap: "30px", alignItems: "center", position: "absolute", top: "300px" }}>
						<ErrorOutlineRoundedIcon htmlColor={themeObj.contrast} sx={{ fontSize: "70px" }} />
						<Typography fontSize="2rem" fontWeight={700} color={themeObj.contrast}>Display Error</Typography>
					</Stack>
					<Typography textAlign="center" fontSize="1rem" color={themeObj.title}>We're excited to have you on our platform! However, our website is currently optimized for smaller screens only. </Typography>
					<Typography textAlign="center" fontSize="1rem" color={themeObj.subtitle}>If you're trying to log in on a larger screen, you may experience some issues.</Typography>
					<Typography textAlign="center" fontSize="1.2rem" fontWeight={700} color={themeObj.title}>Please switch to a device with a smaller screen to continue.</Typography>
				</Box>
			}
			{redirectingTo && <AlertComponent type='info' message={`Redirecting to ${redirectingTo} page.`} outlined={true} />}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				closeOnClick
				draggable
				pauseOnHover
				limit={3}
				transition={Slide}
			/>
		</Box>
	)
}