import { Stack, Grid, TextField, Paper, Typography, MenuItem, InputAdornment, Box, IconButton, Collapse } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "../../context/ThemeContext";
import { ExpandMoreRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import DateField from "../Sales/DateField";
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc);

const validationSchema = Yup.object({
	charges_amount: Yup.number().typeError('Charges must be a number'),
});

const PaymentDetails = forwardRef(({ onFormSubmit, amount, updateCharges }, ref) => {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(false);

	const formik = useFormik({
		initialValues: {
			payment_method: 'cash',
			charges_amount: amount.charges_amount ?? '0',
			payment_date: dayjs(Date.now()),
			status: 'paid'
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit("payment", {
				payment_method: values.payment_method,
				status: values.status,
				payment_date: dayjs.utc(values.payment_date).format(),
				charges: amount.charges_amount,
				amount: amount.sub_total + amount.cgst_amount + amount.sgst_amount - amount.discount_amount,
			});
		},
	});

	useImperativeHandle(ref, () => ({
		hide: () => {
			setExpanded(false)
		},
		show: () => {
			setExpanded(true);
		},
		requestSubmit: async () => {
			await formik.submitForm();
		},
		reset: () => {
			formik.resetForm();
		}
	}));

	useEffect(() => {
		if (Number(formik.values.charges_amount) && !formik.errors.charges_amount) {
			updateCharges(Number(formik.values.charges_amount));
		}
	}, [formik.values]);

	return (
		<Box>
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 10px', borderRadius: 1, backgroundColor: themeObj.secondary, height: '60px', display: "flex", alignItems: "center" }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={10.5}>
						<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
							<Typography textAlign="left" color={themeObj.title} fontSize="1.1rem" >Payment Details</Typography>
							<Stack alignItems="flex-end">
								<Typography color={themeObj.title} fontSize="0.7rem">Payment Method</Typography>
								<Typography color={themeObj.title} fontSize="0.9rem">{formik.values.payment_method === 'cash' ? 'Cash' : 'UPI'}</Typography>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={1.5}>
						<IconButton >
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<form
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper sx={{ borderRadius: 1, padding: "10px", backgroundColor: themeObj.card }}>
						<Stack gap={1.5}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<TextField
										select
										size="small"
										fullWidth
										id="payment_method"
										name="payment_method"
										label="Payment Method"
										value={formik.values.payment_method}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.payment_method && Boolean(formik.errors.payment_method)}
										helperText={formik.touched.payment_method && formik.errors.payment_method}
										sx={{
											fontSize: "0.8rem",
											color: themeObj.title,
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											},
											'& .MuiSelect-icon': {
												color: themeObj.title
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
										SelectProps={{
											MenuProps: {
												sx: {
													'& .MuiMenu-paper': {
														backgroundColor: themeObj.card,
													},
													'& .MuiMenu-list': {
														p: 0
													}
												}
											}
										}}
									>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="cash">Cash</MenuItem>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="upi">UPI</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={6}>
									<TextField
										select
										size="small"
										fullWidth
										id="status"
										name="status"
										label="Payment Status"
										value={formik.values.status}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.status && Boolean(formik.errors.status)}
										helperText={formik.touched.status && formik.errors.status}
										sx={{
											fontSize: "0.8rem",
											color: themeObj.title,
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											},
											'& .MuiSelect-icon': {
												color: themeObj.title
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
										SelectProps={{
											MenuProps: {
												sx: {
													'& .MuiMenu-paper': {
														backgroundColor: themeObj.card,
													},
													'& .MuiMenu-list': {
														p: 0
													}
												}
											}
										}}
									>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="paid">Paid</MenuItem>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="unpaid">Unpaid</MenuItem>
									</TextField>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										label="Amount"
										value={Math.round((
											(amount.sub_total || 0) +
											(amount.cgst_amount || 0) +
											(amount.sgst_amount || 0) -
											(amount.discount_amount || 0)
										) * 100) / 100 ?? '0'}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											startAdornment: <InputAdornment position="start"><Typography sx={{ lineHeight: "unset" }} color={themeObj.subtitle}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										id="charges_amount"
										name="charges_amount"
										label="Charges"
										value={formik.values.charges_amount ?? ''}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.charges_amount && Boolean(formik.errors.charges_amount)}
										helperText={formik.touched.charges_amount && formik.errors.charges_amount}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											startAdornment: <InputAdornment position="start"><Typography sx={{ lineHeight: "unset" }} color={themeObj.subtitle}>₹</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
							</Grid>
							<DateField
								size="small"
								fullWidth
								id="payment_date"
								name="payment_date"
								label="Payment Date"
								value={formik.values.payment_date}
								onChange={formik.handleChange}
							/>
						</Stack>
					</Paper>
				</form>
			</Collapse>
		</Box>
	)
});

export default PaymentDetails;