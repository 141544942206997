import axios from 'axios';
import { auth } from '../services/firebase';

const testUID = '@JP9xjTHYB8dEi1yF6HSQVaEaHGB3';

const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiInstance.interceptors.request.use(
	async (config) => {
		const user = auth.currentUser;
		if (user) {
			const token = await user.getIdToken(true);
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

apiInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 401) {
			if (auth.currentUser) {
				try {
					await auth.currentUser.getIdToken(true);
					return apiInstance(error.config);
				} catch (refreshError) {
					auth.signOut();
					window.location.href = '/signin';
					return Promise.reject(refreshError);
				}
			}
		}
		return Promise.reject(error);
	}
);

export default apiInstance;