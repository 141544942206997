import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip, Grid, IconButton } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { updateTicket } from "../../apis/ticketApi";
import { CategoryRounded, Close, Inventory2Rounded, PaidRounded } from "@mui/icons-material";

export default function NotificationItem(props) {
	const { themeObj } = useTheme();
	const [open, setOpen] = useState(false);

	const iconMap = {
		"stock": <Inventory2Rounded fontSize="small" htmlColor="#a855f7" />,
		"transaction": <PaidRounded fontSize="small" htmlColor="#22c55e" />,
		"inventory": <CategoryRounded fontSize="small" htmlColor="#f97316" />,
	}

	const clickHandler = () => {
		if (props.notification_status === 'unread') {
			props.onClick();
		}
		setOpen(true);
	}

	const timeAgo = (date) => {
		const seconds = Math.floor((new Date() - new Date(date)) / 1000);
		const days = Math.floor(seconds / 86400);
		const weeks = Math.floor(days / 7);
		const months = Math.floor(days / 30);

		if (days < 7) {
			return days === 1 ? '1 day ago' : `${days} days ago`;
		} else if (weeks < 4) {
			return weeks === 1 ? 'a week ago' : `${weeks} weeks ago`;
		} else {
			return months === 1 ? 'a month ago' : `${months} months ago`;
		}
	}

	return (
		<Box>
			<Button
				variant="text"
				sx={{
					color: 'revert',
					textTransform: 'none',
					width: '100%',
					padding: 0,
					borderRadius: '5px',
					border: '2px solid',
					borderColor: props.selected ? themeObj.primary : 'transparent',
					transition: 'border-color 0.5s',
				}}
				onClick={clickHandler}
			>
				<Paper elevation={3} sx={{ width: '100%', padding: '15px', backgroundColor: themeObj.card }}>
					<Stack flexDirection="row" justifyContent="space-between" sx={{ textOverflow: "ellipsis", overflow: "hidden" }} gap={2.5}>
						<Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '100%' }} gap={1.5}>
							{iconMap[props.notification_category]}
							<Typography textAlign="left" fontSize="1rem" color={themeObj.title}>{props.notification_title}</Typography>
						</Stack>
						<div style={{ height: 8, width: 8, borderRadius: "50%", backgroundColor: props.notification_status === 'unread' ? themeObj.primary : 'transparent', position: "relative", top: 8, right: 8 }}>
							{props.notification_status === 'unread' &&
								<Box sx={{
									'@keyframes ping': {
										'75%, 100%': {
											transform: 'scale(2.8)',
											opacity: 0,
										},
									},
									'::before': {
										content: '""',
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										borderRadius: '50%',
										backgroundColor: themeObj.primary,
										animation: 'ping 1.2s ease-out infinite',
									}
								}}
								/>
							}
						</div>
					</Stack>
				</Paper>
			</Button>
			<Dialog
				onClose={() => setOpen(false)}
				open={open}
				sx={{ minHeight: "70vh" }}
				maxWidth='sm'
				fullWidth
				PaperProps={{
					sx: {
						backgroundColor: themeObj.card,
						color: themeObj.title,
						borderRadius: '10px',
					}
				}}
			>
				<DialogTitle sx={{ m: 0, p: 2, pr: 6, pb: 0 }}>
					{props.notification_title}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => setOpen(false)}
					sx={{
						position: 'absolute',
						right: 12,
						top: 12,
					}}
				>
					<Close color="error" />
				</IconButton>
				<DialogContent sx={{ p: 0, px: 2 }}>
					<Stack gap={0.5} flexDirection="row" alignItems="center" justifyContent="space-between">
						<Stack gap={0.5} flexDirection="row" alignItems="center">
							{iconMap[props.notification_category]}
							<Typography sx={{ color: themeObj.subtitle, textTransform: 'capitalize' }}>
								{props.notification_category}
							</Typography>
						</Stack>
						<Typography sx={{ color: themeObj.subtitle, textTransform: 'capitalize' }}>
							{timeAgo(props.updated_at)}
						</Typography>
					</Stack>
				</DialogContent>
				<DialogContent>
					<Typography sx={{ color: themeObj.subtitle }}>
						{props.notification_description}
					</Typography>
				</DialogContent>
			</Dialog>
		</Box>
	)
}