import { Stack, Grid, TextField, Paper, Typography, MenuItem, InputAdornment, Box, IconButton, Collapse } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "../../context/ThemeContext";
import { ExpandMoreRounded } from "@mui/icons-material";
import VendorAutocomplete from "./VendorAutocomplete";
import 'yup-phone-lite';

const validationSchema = Yup.object({
	phone: Yup.string().phone('IN', 'Invalid Phone Number'),
	email: Yup.string().email('Invalid email'),
});

const VendorDetails = forwardRef(({ onFormSubmit, updateCharges }, ref) => {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(false);
	const autoCompleteRef = useRef(null);

	const formik = useFormik({
		initialValues: {
			vendor_id: '',
			name: '',
			phone: '',
			email: '',
			gstin: '',
			address: '',
			tags: [],
			type: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit("vendor", {
				...values
			});
		},
	});

	useImperativeHandle(ref, () => ({
		hide: () => {
			setExpanded(false)
		},
		show: () => {
			setExpanded(true);
		},
		requestSubmit: async () => {
			await formik.submitForm();
		},
		setValues: (values) => formik.setValues(values),
		reset: () => {
			formik.resetForm();
			autoCompleteRef.current?.clear();
		}
	}));

	useEffect(() => {
		if (Number(formik.values.charges_amount) && !formik.errors.charges_amount) {
			updateCharges(Number(formik.values.charges_amount));
		}
	}, [formik.values]);

	const onAutocompleteResult = (val) => {
		if (val.type === 'TYPESENSE') {
			formik.setFieldValue('vendor_id', val.vendor.vendor_id);
			formik.setFieldValue('name', val.vendor.name);
			formik.setFieldValue('type', val.vendor.type);
			formik.setFieldValue('phone', val.vendor.phone);
			formik.setFieldValue('email', val.vendor.email);
			formik.setFieldValue('gstin', val.vendor.gstin);
			formik.setFieldValue('address', val.vendor.address);
			formik.setFieldValue('tags', val.vendor.tags);
			setExpanded(false);
		}
		else if (val.type === 'CLEAR') {
			formik.setFieldValue('vendor_id', '');
			formik.setFieldValue('name', '');
			formik.setFieldValue('type', '');
			formik.setFieldValue('phone', '');
			formik.setFieldValue('email', '');
			formik.setFieldValue('gstin', '');
			formik.setFieldValue('address', '');
			formik.setFieldValue('tags', []);
		}
		else {
			formik.setFieldValue('name', val.vendor_name);
		}
	};

	return (
		<Box>
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 10px', borderRadius: 1, backgroundColor: themeObj.secondary, height: '60px', display: "flex", alignItems: "center" }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={10.5}>
						<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
							<Typography textAlign="left" color={themeObj.title} fontSize="1.1rem" >Vendor Details</Typography>
							<Stack alignItems="flex-end">
								<Typography color={themeObj.title} fontSize="0.7rem">Vendor</Typography>
								<Typography color={themeObj.title} fontSize="0.9rem">{formik.values.name.length > 20 ? `${formik.values.name.substring(0, 15)}...` : formik.values.name}</Typography>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={1.5}>
						<IconButton >
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<form
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper sx={{ borderRadius: 1, padding: "10px", backgroundColor: themeObj.card }}>
						<Stack gap={1.5}>
							<VendorAutocomplete onResult={onAutocompleteResult} ref={autoCompleteRef}/>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<TextField
										size="small"
										fullWidth
										id="phone"
										name="phone"
										label="Phone Number"
										value={formik.values.phone ?? ''}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.phone && Boolean(formik.errors.phone)}
										helperText={formik.touched.phone && formik.errors.phone}
										sx={{
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											startAdornment: <InputAdornment position="start"><Typography sx={{ lineHeight: "unset" }} color={themeObj.subtitle}>+91</Typography></InputAdornment>,
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										select
										size="small"
										fullWidth
										id="type"
										name="type"
										label="Vendor Type"
										value={formik.values.type}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.type && Boolean(formik.errors.type)}
										helperText={formik.touched.type && formik.errors.type}
										sx={{
											fontSize: "0.8rem",
											color: themeObj.title,
											'& .MuiOutlinedInput-root': {
												'&.Mui-focused fieldset': {
													borderColor: themeObj.primary,
												},
												'& fieldset': {
													borderColor: themeObj.subtitle,
												},
											},
											'& .MuiSelect-icon': {
												color: themeObj.title
											}
										}}
										InputLabelProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title,
												'&.Mui-focused': {
													color: themeObj.primary
												},
											}
										}}
										InputProps={{
											sx: {
												fontSize: '1rem',
												color: themeObj.title
											},
										}}
										SelectProps={{
											MenuProps: {
												sx: {
													'& .MuiMenu-paper': {
														backgroundColor: themeObj.card,
													},
													'& .MuiMenu-list': {
														p: 0
													}
												}
											}
										}}
									>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="business">Business</MenuItem>
										<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="normal">Normal</MenuItem>
									</TextField>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								multiline
								id="email"
								name="email"
								label="Email"
								value={formik.values.email ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
							<TextField
								size="small"
								fullWidth
								multiline
								id="address"
								name="address"
								label="Address"
								value={formik.values.address ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.address && Boolean(formik.errors.address)}
								helperText={formik.touched.address && formik.errors.address}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
							<TextField
								size="small"
								fullWidth
								multiline
								id="gstin"
								name="gstin"
								label="GSTIN"
								value={formik.values.gstin ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.gstin && Boolean(formik.errors.gstin)}
								helperText={formik.touched.gstin && formik.errors.gstin}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
							<TextField
								select
								size="small"
								fullWidth
								id="tags"
								name="tags"
								label="Tags"
								value={formik.values.tags}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.tags && Boolean(formik.errors.tags)}
								helperText={formik.touched.tags && formik.errors.tags}
								sx={{
									fontSize: "0.8rem",
									color: themeObj.title,
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									},
									'& .MuiSelect-icon': {
										color: themeObj.title
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
								SelectProps={{
									multiple: true,
									MenuProps: {
										sx: {
											'& .MuiMenu-paper': {
												backgroundColor: themeObj.card,
											},
											'& .MuiMenu-list': {
												p: 0
											}
										}
									}
								}}
							>
								<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="wholesale">Wholesale</MenuItem>
								<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="corporate">Corporate</MenuItem>
								<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="Frequent">Frequent</MenuItem>
							</TextField>
						</Stack>
					</Paper>
				</form>
			</Collapse>
		</Box>
	)
});

export default VendorDetails;