import React, { useEffect } from "react";
import { IconButton, Stack, Box, Typography, Paper, Chip, Button, Grid, Divider } from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import Accordian from "../../components/Transaction/Accordian";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import Item from "../../components/Transaction/Item";
import { viewTransactionBill } from "../../apis/transactionApi";

export default function SaleTransactionDetails({ onBack, data }) {
	const { themeObj } = useTheme();
	const [bill, setBill] = React.useState(data.bill);

	useEffect(() => {
		const getBill = async () => {
			const response = await viewTransactionBill(data.transaction_id);
			if (response.success) {
				const data = response.data.bill_link
				setBill(data);
			}
		}
		if (!data.bill) {
			getBill();
		}
	}, [])

	return (
		<Grid container spacing={1.5}>
			<Grid item xs={12}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<IconButton onClick={onBack}>
						<KeyboardBackspaceRounded htmlColor={themeObj.primary} />
					</IconButton>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
					<Stack gap={1} sx={{ maxWidth: "100%" }}>
						<Stack direction="row" gap={1} alignItems="center">
							<Typography sx={{ textOverflow: "ellipsis", textWrap: "nowrap", overflow: "hidden" }} color={themeObj.title} fontSize="1.1rem">{data.transaction_id}</Typography>
							<Chip sx={{ textTransform: 'capitalize' }} label={data?.payments?.status} variant="outlined" size="small" color="success" />
						</Stack>
						<Typography color={themeObj.subtitle} fontSize="0.7rem">{new Date(data.transaction_date).toUTCString()}</Typography>
					</Stack>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Button
					fullWidth
					onClick={() => window.open(bill, "_blank")}
					disabled={!bill}
					sx={{
						backgroundColor: themeObj.primary,
						color: themeObj.title,
						transition: "all 0.3s",
						"&:hover": {
							backgroundColor: themeObj.primary_hover,
						},
						"&:disabled": {
							backgroundColor: themeObj.subtitle,
							color: themeObj.title
						}
					}}
				>
					{bill ? "Open Bill" : "Generating Bill..."}
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
					<Stack>
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Subtotal</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Typography color={themeObj.title} fontSize="1rem">₹ {data.sub_total.toFixed(2)}</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">CGST Amount</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
									<Typography color={themeObj.subtitle} fontSize="1rem">+</Typography>
									<Typography color={themeObj.title} fontSize="1rem">₹ {data.cgst_amount.toFixed(2)}</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">SGST Amount</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
									<Typography color={themeObj.subtitle} fontSize="1rem">+</Typography>
									<Typography color={themeObj.title} fontSize="1rem">₹ {data.sgst_amount.toFixed(2)}</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">Charges</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
									<Typography color={themeObj.subtitle} fontSize="1rem">+</Typography>
									<Typography color={themeObj.title} fontSize="1rem">₹ {data.charges_amount.toFixed(2)}</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">Discount</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Stack justifyContent="space-between" alignItems="center" direction="row" gap={1}>
									<Typography color={themeObj.subtitle} fontSize="1rem">-</Typography>
									<Typography color={themeObj.title} fontSize="1rem">₹ {data.discount_amount.toFixed(2)}</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Divider sx={{ borderColor: themeObj.subtitle, my: 1 }} />
						<Grid container spacing={1}>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
								<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.9rem">Total</Typography>
							</Grid>
							<Grid item xs={6} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end' }}>
								<Typography color={themeObj.title} fontSize="1rem">₹ {data.total_amount.toFixed(2)}</Typography>
							</Grid>
						</Grid>
					</Stack>
				</Paper>
			</Grid>
			{data.customer_details &&
				<Grid item xs={12}>
					<Accordian label="Customer Details">
						<Stack gap={0.5}>
							<Typography color={themeObj.title} fontSize="1.1rem" fontWeight={600}>{data.customer_details.name}</Typography>
							<Typography color={themeObj.subtitle} fontSize="1rem">{data.customer_details.gstin}</Typography>
							<Typography color={themeObj.title} fontSize="0.8rem">{data.customer_details.phone}</Typography>
							<Typography color={themeObj.title} fontSize="0.8rem">{data.customer_details.email}</Typography>
							<Typography color={themeObj.title} fontSize="0.8rem">{data.customer_details.address}</Typography>
						</Stack>
					</Accordian>
				</Grid>
			}
			{data.seller_details &&
				<Grid item xs={12}>
					<Accordian label="Seller Details">
						<Stack gap={0.5}>
							<Typography color={themeObj.title} fontSize="1.1rem" fontWeight={600}>{data.seller_details.name}</Typography>
							<Typography color={themeObj.title} fontSize="0.8rem">{data.seller_details.phone}</Typography>
							<Typography color={themeObj.title} fontSize="0.8rem">{data.seller_details.email}</Typography>
						</Stack>
					</Accordian>
				</Grid>
			}
			<Grid item xs={12}>
				<Divider variant="middle" sx={{ "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="1.4rem">Items</Typography>
				</Divider>
				<Stack gap={1}>
					{data.items.map((item, index) => <Item key={index} data={item} />)}
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ height: "20px" }} />
			</Grid>
		</Grid>
	);
}