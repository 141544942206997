import axios from 'axios';

// Initialize Typesense client
const config = {
	host: process.env.REACT_APP_TYPESENSE_HOST,
	port: process.env.REACT_APP_TYPESENSE_PORT,
	apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
}

export const searchItemsTypesense = async ({
	query = '*',
	categories = null,
	barcodeId = null,
	page = 1,
	perPage = 5,
}) => {
	const filterOptions = { store_id: JSON.parse(localStorage.getItem("store"))?.store_id, categories, barcodeId }
	const searchParameters = {
		q: query,
		query_by: 'item_name,item_category,item_description,item_tags',
		page: page,
		per_page: perPage,
		filter_by: Object.entries(filterOptions).filter(e => e[1]).map(e => `${e[0]}:${e[1]}`).join(' && '),
	};


	try {
		const response = await axios.get(
			`https://${config.host}:${config.port}/collections/items/documents/search`, {
			headers: {
				'X-TYPESENSE-API-KEY': config.apiKey,
			},
			params: searchParameters,
		}
		);

		const results = response.data;
		const processedResults = results.hits.map(hit => {
			const item = hit.document;
			return {
				item_id: item.item_id,
				id: item.id, // New field added
				store_id: item.store_id,
				barcode_id: item.barcode_id,
				item_name: item.item_name,
				item_description: item.item_description,
				item_category: item.item_category,
				item_tags: item.item_tags,
				qt_type: item.qt_type,
				cost_price: item.cost_price,
				selling_price: item.selling_price,
				understock_qt: item.understock_qt,
				overstock_qt: item.overstock_qt,
				quantity: item.quantity,
				image_url: item.image_url, // New field added
				hsn_sac_code: item.hsn_sac_code, // New field added
			};
		});

		return {
			items: processedResults,
			total: results.found,
			page: page,
			per_page: perPage,
		};

	} catch (error) {
		console.error('Error during Typesense search:', error);
		throw error;
	}
};

export const searchVendorsTypesense = async ({
	query = '*',
	storeId = null,
	page = 1,
	perPage = 5,
}) => {
	const filterOptions = { storeId }
	const searchParameters = {
		q: query,
		query_by: 'name,phone,email',
		page: page,
		per_page: perPage,
		filter_by: Object.entries(filterOptions).filter(e => e[1]).map(e => `${e[0]}:${e[1]}`).join(' && '),
	};

	try {
		const response = await axios.get(
			`https://${config.host}:${config.port}/collections/vendors/documents/search`, {
			headers: {
				'X-TYPESENSE-API-KEY': config.apiKey,
			},
			params: searchParameters,
		}
		);

		const results = response.data;
		const processedResults = results.hits.map(hit => {
			const vendor = hit.document;
			return {
				id: vendor.id,
				vendor_id: vendor.vendor_id,
				store_id: vendor.store_id,
				name: vendor.name,
				phone: vendor.phone,
				email: vendor.email,
				address: vendor.address,
				gstin: vendor.gstin,
				tags: vendor.tags,
				type: vendor.type,
				created_at: vendor.created_at,
				updated_at: vendor.updated_at,
			};
		});

		return {
			vendors: processedResults,
			total: results.found,
			page: page,
			per_page: perPage,
		};

	} catch (error) {
		console.error('Error during Typesense search:', error);
		throw error;
	}
};

export const customerSearchTypesense = async ({
	query = '*',
	storeId = null,
	page = 1,
	perPage = 5,
}) => {
	const filterOptions = { storeId }
	const searchParameters = {
		q: query,
		query_by: 'name,phone,email',
		page: page,
		per_page: perPage,
		filter_by: Object.entries(filterOptions).filter(e => e[1]).map(e => `${e[0]}:${e[1]}`).join(' && '),
	};

	try {
		const response = await axios.get(
			`https://${config.host}:${config.port}/collections/customers/documents/search`, {
			headers: {
				'X-TYPESENSE-API-KEY': config.apiKey,
			},
			params: searchParameters,
		}
		);

		const results = response.data;
		const processedResults = results.hits.map(hit => {
			const customer = hit.document;
			return {
				id: customer.id,
				customer_id: customer.customer_id,
				store_id: customer.store_id,
				name: customer.name,
				phone: customer.phone,
				email: customer.email,
				address: customer.address,
				gstin: customer.gstin,
				tags: customer.tags,
				type: customer.type,
				created_at: customer.created_at,
				updated_at: customer.updated_at,
			};
		});

		return {
			customers: processedResults,
			total: results.found,
			page: page,
			per_page: perPage,
		};

	} catch (error) {
		console.error('Error during Typesense search:', error);
		throw error;
	}
};