import React from "react";
import { useLocale } from "../../context/LocaleContext";
import { Collapse, Divider, Typography, Box } from "@mui/material";
import Pagination from "../../components/Common/Pagination";
import TransactionItem from "../../components/Transaction/TransactionItem";
import { TransitionGroup } from "react-transition-group";
import TransactionItemLoader from "../../components/Transaction/TransactionItemLoader";

// import StatisticDisplay from "../../components/Transaction/StatisticDisplay";
import { useTheme } from "../../context/ThemeContext";
import Filters from "../../components/Transaction/FIlters";
import InstantSaleCheck from "../../components/Transaction/InstantSaleCheck";

export default function SaleTransactionSearch({ loaded, page, setPage, groupedTransactions, renderedData, doSearch, searchCallback, hasNext, onClick, types, setTypes, noData, filterSearch }) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();

	return (
		<>
			{/* <StatisticDisplay
				action={types}
				setAction={(risk) => { setTypes(risk); doSearch(risk) }}
			/> */}
			<InstantSaleCheck />
			<TransitionGroup>
				{loaded ?
					(renderedData?.length !== 0 &&
						Object.keys(groupedTransactions).map(date => (
							<Collapse key={date}>
								<Divider variant="middle" sx={{ marginBottom: '7px', "&::before, &::after": { borderColor: themeObj.subtitle } }}><Typography color={themeObj.title}>{date}</Typography></Divider>
								<TransitionGroup>
									{groupedTransactions[date].map(e => (
										<Collapse key={e.transaction_id}>
											<TransactionItem {...e} onClick={() => onClick(e)} label={messages.transaction.chips[e.transaction_type]} />
										</Collapse>
									))}
								</TransitionGroup>
							</Collapse>
						))
					)
					:
					Array.from({ length: 20 }, (e, i) =>
						<Collapse key={i} sx={{ marginBottom: '12px' }}>
							<TransactionItemLoader />
						</Collapse>
					)
				}
			</TransitionGroup>
			{(noData || (loaded && renderedData.length === 0)) && (
				<Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "center", width: "100%", my: 5 }}>
					<Typography variant="h5" sx={{ color: themeObj.title }}>No Data <span style={{ color: themeObj.primary, fontWeight: 600 }}>!</span></Typography>
				</Box>
			)}
			{!(noData || (loaded && renderedData.length === 0))  &&
				<Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
					<Pagination
						page={page}
						setPage={setPage}
						hasNext={hasNext}
					/>
				</Box>
			}
			<Box sx={{ height: 70 }}></Box>
			<Filters applyCallback={(data) => filterSearch(data)}/>
		</>
	);
}